import React, { useEffect, useRef } from 'react';
import CollectionForm from 'redux/modules/HomeAudit/CollectionForm/container';
import { SERVICES } from 'redux/modules/HomeAudit/constants';
import SectionCentered from 'modules/layout/SectionCentered';
import { Dialog, IconButton, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import {
  productPropType,
  tariffPropType,
  universalBenefits,
} from 'app/redux/modules/Mobile/constants';
import { FlagPointIcon } from '@utilitywarehouse/partner-ui-icons';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import SummaryPanel from 'modules/Shared/SummaryPanel';
import { price } from 'app/constants/propTypes';
import NavigationPane from 'modules/Shared/NavigationButton/NavigationPane';
import TariffSelection from 'modules/Mobile/TariffSelection';
import SimSelection from 'modules/Mobile/SimSelection';
import { useIsMobile } from 'app/components/modules/OrderSummary/ServicesTotal/Save/utils';
import LegalDropdown from './LegalDropdown';
import { FeatureFlagsApi } from 'app/redux/modules/FeatureFlags/api';
import { FLAGS } from 'app/redux/modules/FeatureFlags/constants';
import useFeature from 'app/lib/analytics/Optimizely/useFeature';
import { features, variations } from 'app/lib/analytics/Optimizely/features';
import { Flex } from '@utilitywarehouse/web-ui';
import { Close as CloseIcon } from '@material-ui/icons';
import RestrictedButton from 'app/redux/modules/RemoteSession/v2/RestrictedButton';

import { TARIFFS } from './constants';

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(4),
  },
  header: {
    marginTop: theme.spacing(2),
  },
  benefitsList: {
    marginTop: theme.spacing(4),
    display: 'flex',
    gap: theme.spacing(1, 3),
    flexWrap: 'wrap',
    [theme.breakpoints.up('sm')]: { justifyContent: 'center' },
  },
  benefitItem: {
    display: 'flex',
    columnGap: theme.spacing(1),
  },
  successColour: {
    color: theme.palette.success.main,
  },
  section: {
    marginTop: theme.spacing(4),
  },
  image: {
    width: 90,
    height: 90,
    marginBottom: 10,
  },
  dialog: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(4),
    borderRadius: '20px !important',
    maxWidth: '500px !important',
  },
  savingsTip: {
    fontSize: 24,
  },
  savingsActions: {
    width: '100%',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  savingsAction: {
    flex: 1,
    padding: `${theme.spacing(2.5)}px !important`,
    height: '50px !important',
  },
  closeIcon: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.secondary.main,
  },
}));

const getSecondSimDialogContent = ({ energySelected, broadbandSelected }) => {
  let benefit = 'an extra £50 bundle discount';

  if (!energySelected && broadbandSelected) {
    benefit = '£400 towards early termination fees';
  }

  if (energySelected && !broadbandSelected) {
    benefit = 'cheaper energy';
  }

  return `Don't forget you'll unlock ${benefit} if you add a second SIM!`;
};

const Mobile = ({
  selectedTariff,
  tariffs,
  selectedProducts,
  monthlyTotal,
  nextSimDiscounted,
  openSpendCapDialog,
  openNumberOptionsDialog,
  onSelectTariff,
  submitMobilePage,
  removeProduct,
  addProduct,
  getTariffsRequest,
  secondMobileDialogOpen,
  openSecondMobileDialog,
  closeSecondMobileDialog,
  secondMobileSelected,
  energySelected,
  broadbandSelected,
}) => {
  const classes = useStyles();
  const simSelectionRef = useRef(null);

  useEffect(() => {
    getTariffsRequest();
  }, [getTariffsRequest]);

  const isMobile = useIsMobile();

  const newMobileTariffsEnabled = useSelector(
    FeatureFlagsApi.getFlagSelector(FLAGS.NEW_MOBILE_TARIFFS)
  );
  const stickyNavFlagEnabled = useSelector(
    FeatureFlagsApi.getFlagSelector(FLAGS.CTA_STICKY_NAVIGATION_ENABLED)
  );
  const { variant: stickyNavVariant } = useFeature(features.STICKY_NAV);
  const stickyNavEnabled =
    stickyNavFlagEnabled ||
    stickyNavVariant === variations[features.STICKY_NAV].ON;

  const selectTariffAndScroll = (tariff) => {
    onSelectTariff(tariff);
    if (isMobile || stickyNavEnabled) {
      simSelectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const onNext = () => {
    const isBenefitEligible =
      selectedProducts.length === 1 && (energySelected || broadbandSelected);

    if (secondMobileSelected && isBenefitEligible) {
      openSecondMobileDialog();
    } else {
      submitMobilePage();
    }
  };

  const keepSingleSim = () => {
    submitMobilePage();
    closeSecondMobileDialog();
  };

  const addAnotherProduct = () => {
    addProduct();
    closeSecondMobileDialog();
    simSelectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      <SectionCentered classes={{ root: classes.container }}>
        <CollectionForm
          service={SERVICES.MOBILE}
          tooltip="We'll use this to work out the price difference between us and your existing provider, so it's best if you tell us about the cost of what we'll be replacing"
        />
        <Typography variant="h2" className={classes.header} color="primary">
          {newMobileTariffsEnabled
            ? 'Choose your SIM plan'
            : 'Choose your first SIM'}
        </Typography>
        <div className={classes.benefitsList}>
          {universalBenefits.map((benefit) => (
            <div key={benefit} className={classes.benefitItem}>
              <FlagPointIcon className={classes.successColour} />
              <Typography>{benefit}</Typography>
            </div>
          ))}
        </div>
        <div className={classes.section}>
          <TariffSelection
            onSelectTariff={selectTariffAndScroll}
            selectedTariffLabel={selectedTariff?.label}
            tariffRequestStatus={tariffs.status}
            tariffRequestError={tariffs.error}
            tariffs={tariffs.nodes}
          />
        </div>
        <div ref={simSelectionRef} />
        {selectedTariff && (
          <>
            <Typography
              variant="h2"
              className={classes.section}
              color="primary"
            >
              Review your SIM settings and add more
            </Typography>
            {/* todo: remove mobile price increase info message on the 1st April */}
            {selectedTariff.name === TARIFFS.UNLIMITED_PLUS ? (
              <Typography variant="caption" color="primary">
                Additional Unlimited+ SIMs cost £8/month until 1st April 2025,
                then £10/month.
              </Typography>
            ) : (
              <Typography variant="caption" color="primary">
                &nbsp;
              </Typography>
            )}
          </>
        )}
        {selectedTariff && (
          <div className={classes.section}>
            <SimSelection
              selectedProducts={selectedProducts}
              selectedTariff={selectedTariff}
              nextSimDiscounted={nextSimDiscounted}
              openSpendCapDialog={openSpendCapDialog}
              openNumberOptionsDialog={openNumberOptionsDialog}
              removeProduct={removeProduct}
              addProduct={addProduct}
            />
          </div>
        )}
        {selectedTariff && selectedProducts && (
          <div className={classes.section}>
            <SummaryPanel
              items={selectedProducts.map((sim) => ({
                id: sim.id,
                name: `${selectedTariff.name} SIM`,
                price: selectedTariff.price,
                discountedPrice: sim.discounted
                  ? selectedTariff.discountedPrice
                  : undefined,
              }))}
              monthlyTotal={monthlyTotal}
            />
          </div>
        )}

        <LegalDropdown />
      </SectionCentered>
      <NavigationPane
        helpCtaEnabled
        back
        next
        nextLabel="Next"
        nextHandler={onNext}
        nextDisabled={!selectedTariff || selectedProducts.length === 0}
      />
      <Dialog classes={{ paper: classes.dialog }} open={secondMobileDialogOpen}>
        <IconButton
          className={classes.closeIcon}
          aria-label="close"
          onClick={() => closeSecondMobileDialog()}
        >
          <CloseIcon />
        </IconButton>
        <img
          className={classes.image}
          alt="savings tip"
          src={'images/bundlesPage/bundle_bot.svg'}
        />
        <Flex direction="column" alignItems="center" gap={2}>
          <Typography variant="h4" className={classes.savingsTip}>
            Savings tip
          </Typography>
          <Typography variant="body1" align="center">
            {getSecondSimDialogContent({ energySelected, broadbandSelected })}
          </Typography>
          <Flex gap={2} className={classes.savingsActions}>
            <RestrictedButton
              className={classes.savingsAction}
              variant="outlined"
              onClick={keepSingleSim}
              label="Keep one SIM only"
            />
            <RestrictedButton
              className={classes.savingsAction}
              onClick={addAnotherProduct}
              label="Add another SIM"
              variant="contained"
              dataTestId="MobileSecondSimPopupAdd"
            />
          </Flex>
        </Flex>
      </Dialog>
    </>
  );
};

Mobile.propTypes = {
  selectedTariff: tariffPropType,
  tariffs: PropTypes.shape({
    status: PropTypes.string,
    error: PropTypes.string,
    nodes: PropTypes.arrayOf(tariffPropType),
  }),
  selectedProducts: PropTypes.arrayOf(productPropType).isRequired,
  monthlyTotal: price,
  nextSimDiscounted: PropTypes.bool.isRequired,
  openSpendCapDialog: PropTypes.func.isRequired,
  openNumberOptionsDialog: PropTypes.func.isRequired,
  removeProduct: PropTypes.func.isRequired,
  addProduct: PropTypes.func.isRequired,
  onSelectTariff: PropTypes.func.isRequired,
  submitMobilePage: PropTypes.func.isRequired,
  getTariffsRequest: PropTypes.func.isRequired,
  secondMobileDialogOpen: PropTypes.bool.isRequired,
  openSecondMobileDialog: PropTypes.func.isRequired,
  closeSecondMobileDialog: PropTypes.func.isRequired,
  secondMobileSelected: PropTypes.bool.isRequired,
  energySelected: PropTypes.bool.isRequired,
  broadbandSelected: PropTypes.bool.isRequired,
};

export default Mobile;
